@import '../../scss/variables';

footer {
  text-align: center;
  margin: 0.67em;
  
  span.code {
    font-size: 0.9em;
  }

  @media #{$mq-small} {
    margin: 1em;
  
    span.code {
      font-size: 1em;
    }
  }
}