@import '../../scss/variables';

section#about {
  display: inline-block;

  div.content {
    display: inline-block;

    @media #{$mq-small} {
      display: block;
      float: left;
      width: 65%;
    }
  }

  aside {
    display: inline-block;

    @media #{$mq-small} {
      display: block;
      float: right;
      width: 30%;
    }

    ul {
      display: grid;
      grid-template-columns: 	repeat(3, minmax(100px, 200px));

      @media #{$mq-small} {
        grid-template-columns: 	repeat(2, minmax(100px, 200px));
      }

      @media #{$mq-medium} {
        grid-template-columns: 	repeat(3, minmax(100px, 200px));
      }
  
      li {
        display: inline;
        margin-bottom: 0.67em;
      }
    }
  }
}

