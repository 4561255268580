@import '../../scss/variables';

.timeline {
  &>ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &>ul>li {
    display: block;
  }
}

.timeline_element {
	position: relative;	
  overflow: hidden;
  border-top: 1px solid gray;
  padding-top: 1em;
  margin-top: 1em
}

.timeline_element-date {
  color: $code-color;
  font-family: $code-font;
  font-size: 1.2em;
  font-weight: 300;

  // mobile
  display: block;
  width: auto;
  position: static;
  padding: 0;
  border: none;

  div {
    font-size: 1em;
  }
  
  &::after {
    display: none;
  }
}

.timeline_element-contents {
  margin-left: 0;
  padding-top: 0;
  padding-bottom: 1em;
  overflow: hidden;

  .timeline_element-text li, .timeline_element-text p {
    font-size: 0.9em;
  }

  li {
    font-family: $default-font !important;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 0.2em;
  }
}

.timeline_element-text {
	width: 50%;
	float: left;
}

.timeline_element-img {
	width: 50%;
	float: right;
	text-align: center;
  padding-top: 3em;
  
  img {
    max-width: 90%;
	  max-height: 20vw;
  }
}

@media screen and (min-width: $bp-small) {
  .timeline {
		position: relative;
		margin-top: 2em;
  }

  .timeline_element {
    border-top: none;
    margin: 0;
    padding: 0;
  }
  
	.timeline_element-date {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		padding: 30px 15px;
		width: 50px;
		text-align: right;
		line-height: 30px;
    border-right: 1px solid gray;
    
    &::after {
      content: " ";
      position: absolute;
      top: 42.5px;
      right: -4px;
      display: block;
      width: 8px;
      height: 8px;
      background-color: $primary-color;
    }
  }
  
	.timeline_element:first-child {
    .timeline_element-date {
      padding-top: 0;
      line-height: 1.5;
      margin-top: 10px;

      &::after {
        top: 0;
      }

      .timeline_element-date-text {
        margin-top: -10px;
        display: block;
      }
    }

    .timeline_element-contents {
      padding-top: 0;
    }
  }

  .timeline_element-contents {
    margin-left: 100px;
    padding: 30px 0;
    overflow: hidden;

    .timeline_element-text li, .timeline_element-text p, p {
      font-size: 1em;
    }
  }
  
	.timeline_element+.timeline_element .timeline_element-contents {
		border-top: 1px solid gray;
  }
}

@media screen and (max-width: 1526px) {
	.timeline_element-img {
		width: unset;
		float: unset;
		text-align: center;
    padding-top: 40px;
    
    img {
      max-width: 100%;
		  max-height: 450px;
    }
	}

	.timeline_element-text {
		width: unset;
		float: unset;
	}
}
