@import 'scss/variables';

html, body, main {
  background-color: $background;
}

main {
  margin: 0 6%;

  @media #{$mq-small} {
    margin: 0 6%;
  }

  @media #{$mq-medium} {
    margin: 0 8%;
  }

  @media #{$mq-large} {
    margin: 0 10%;
  }
}

body.blur main > * {
  filter: blur(5px) brightness(0.7);
  transition: $transition;
  pointer-events: none;
  user-select: none;
}

section {
  padding: 0 5px;
  overflow: hidden;

  &:not(:first-child) {
    margin-bottom: 16vh;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    padding-top: 40%;

    @media #{$mq-xsmall} {
      padding-top: 20%;
    }
  
    @media #{$mq-medium} {
      padding-top: 12%;
    }
  }
}

span {
  &.code {
    font-family: $code-font;
    font-size: 18px;
    font-weight: 300;
    color: $code-color;

    @media #{$mq-xsmall} {
      font-size: 20px;
    }
  }
}

h2 span {
  margin-right: 0.5em;
}