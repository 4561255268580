@import '../../scss/variables';

.tech-button {
  display: inline-block;
  padding: 0.4em;
  border: 1px $code-color solid;
  background: rgba(231, 219, 116, 0.05);
  margin-right: 5px;
  margin-top: 0.67em;
  line-height: 15px;

  @media #{$mq-xsmall} {
    padding: 0.5em;
    line-height: normal;
  }

  a, span {
    font-family: $code-font;
    font-size: 0.9em;
    font-weight: 300;

    @media #{$mq-xsmall} {
      font-size: 1em;
    }
  }
}
