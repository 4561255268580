$background: #282923;
$primary-color: #d4d5d6;
$secondary-color: #fff;
$tertiary-color: #ddd;
$code-color: #e7db74;
$default-font: 'Open Sans', sans-serif;
$code-font: 'Source Code Pro', monospace;
$selection-color: #b7c9ff;

// Breakpoints
$bp-xsmall: 600px; // 48em
$bp-small: 768px; // 48em
$bp-medium: 1024px; // 64em
$bp-large: 1366px; // 85.375em
$bp-xlarge: 1920px; // 120em
$bp-xxlarge: 2560px; // 160em

// Media Queries
$mq-xsmall: "(min-width: #{$bp-xsmall})";
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

// Misc
$nav-scroll-height: 8vh;
$nav-aside-color: #3D3E39;
$transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);