@import '../../scss/variables';

html {
  scroll-behavior: smooth;
}

#welcome {
  box-sizing: border-box;
  min-height: 90vh;
  padding-bottom: 16vh;
}

#signature {
  margin: 2em 0;
  width: 400px;
  max-width: 80vw;
}

section#welcome div.content {
  margin-block-end: 6%;

  @media #{$mq-xsmall} {
    max-width: 75%;
	}

  @media #{$mq-small} {
    max-width: 75%;
	}

  @media #{$mq-medium} {
    max-width: 60%;
	}
}

#scroll {
  transition: opacity 0.5s ease-in-out;
}

#scroll a {
  padding-top: 80px;
}

$scroll-initial-offset: 100px;

#scroll a span {
  position: absolute;
  bottom: $scroll-initial-offset;
  left: 50%;
  width: 24px;
  height: 24px;
  border-left: 1px solid $code-color;
  border-bottom: 1px solid $code-color;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg) translate(-50%, -50%);
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
#scroll a span:nth-of-type(1) {
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}
#scroll a span:nth-of-type(2) {
  bottom: calc(#{$scroll-initial-offset} + 16px);
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}
#scroll a span:nth-of-type(3) {
  bottom: calc(#{$scroll-initial-offset} + 32px);
  -webkit-animation-delay: .0s;
  animation-delay: .0s;
}
@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#mobile-socials {
  display: block;

  @media #{$mq-xsmall} {
    display: none;
  }
}

#contact {
  display: flex;
}

#email {
  flex: 1;
}

.socials {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin-right: 1em;
  }

  @media #{$mq-xsmall} {
    position: absolute;
    right: 0;

    li {
      margin-right: 0;
      padding-bottom: 1em;
      padding-right: 1em;
      border-right: 1px solid gray;
    }

    li:last-child {
      padding-bottom: 0;
    }
  }
}

#welcome {
  position: relative;
  display: flex;
}

.right {
  display: none;
}

.icon svg {
  fill: white;
  transition: fill 0.2s ease-in-out;

  &:hover {
    fill: $code-color;
  }
}

@media #{$mq-xsmall} {
  .left {
    flex: 1;
  }

  .right {
    display: block;
    position: relative;
  }
}