@import '../../scss/variables';

.image {
  cursor: pointer;
}

.full-width {
  max-width: 90%;
  max-height: 90%;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  cursor: pointer;
  -o-object-fit: contain;
  object-fit: contain;
  transition: transform 0.2s ease-out;
}

.fullscreen {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.exit {
  position: fixed;
  top: 0.67em;
  right: 0.67em;
  line-height: 0.67em;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 2em;
  cursor: pointer;
}

.image-transition-exit {
  opacity: 1;
  display: block;

  img {
    transform: translateY(0);
  }
  &.image-transition-exit-active {
    opacity: 0;
    display: block;

    img {
      transform: translateY(100px);
    }
  }
}
.image-transition-exit-done {
  display: none;
  opacity: 0;
}

.image-transition-enter {
  display: block;
  opacity: 0;

  img {
    transform: translateY(200px);
  }

  &.image-transition-enter-active {
    display: block;
    opacity: 1;

    img {
      transform: translateY(0);
    }
  }
}
.image-transition-enter-done {
  display: block;
}