@import 'scss/variables';

body {
  margin: 0;
	background: $background;
	font-family: $default-font;
	overflow-x: hidden;

	&.blur {
		overflow: hidden;
	}
}

h1 {
	font-size: 2.5em;
	font-weight: 600;
	color: $secondary-color;
	margin: 10px 5px;

	@media #{$mq-small} {
		font-size: 3em;
	}
	
	@media #{$mq-medium} {
		font-size: 4em;
		margin: 0;
  }
}

h2 {
	position: relative;
	font-size: 1.75em;
	font-weight: 600;
	color: $secondary-color;
	margin: 0;
	margin-bottom: 0.67em;

	@media #{$mq-small} {
    font-size: 1.9em;
	}
	
	@media #{$mq-medium} {
    font-size: 2em;
  }

	&::after {
		margin-left: 0.67em;
		position: absolute;
		top: 50%;
		width: 500px;
		height: 1px;
		content: '\a0';
		background-color: gray;
	}

	a {
		color: unset;
		text-decoration: unset;
		cursor: default;
	}
}

h3 {
	position: relative;
	font-size: 1.4em;
	font-weight: 500;
	color: $secondary-color;
	margin: 0;
	margin-bottom: 0.2em;
	overflow: hidden;

	span {
		margin-top: 0.4em;
		font-size: 0.7em;
		font-weight: 400;
		display: block;
		color: $tertiary-color;
	}
}

p { 
	font-size: 1em;
	font-weight: 300;
	font-style: light;
	color: $primary-color;
	margin-block: 1.5em;

	@media #{$mq-small} {
    font-size: 1.2em;
	}

	@media #{$mq-medium} {
    font-size: 1.25em;
	}
}

a.hyperlink, span.hyperlink {
	position: relative;
	color: $code-color;
	text-decoration: none;

	&:hover {
		color: $code-color;
	}

	&:hover::before {
		left: 0;
		right: auto;
		width: 100%;
	}

	&::before {
		content: "";
		position: absolute;
		height: 1px;
		bottom: 0;
		right: 0;
		width: 0;
		background-color: $code-color;
		transition-property: width;
		transition-duration: 0.3s;
		transition-timing-function: ease-out;
	}
}

button {
  font-family: $code-font;
  font-size: 1em;
  font-weight: 300;
  color: $code-color;
  background: none;
  border: 1px $code-color solid;
  border-radius: 3px;
  padding: 0.5em;
  user-select: none;
  cursor: pointer;

  -webkit-transition: 0.2s background;
  -moz-transition: 0.2s background;
  -ms-transition: 0.2s background;
  -o-transition: 0.2s background;
	transition: 0.2s background;

	@media #{$mq-medium} {
		font-size: 1.3em;
	}
	
	&:hover {
		background: rgba(231, 219, 116, 0.05);	
	}

	&:focus {
		outline: 0;	
	}
}

ul.list {
	text-align: left;
	list-style: none;
	overflow: hidden;
	padding: 0;
	margin: 0;

	li {
		position: relative;
		padding-left: 1em;
		font-family: $code-font;
		font-size: 1em;
		font-weight: 300;
		color: $primary-color;
		text-indent: -1em;

		&::before {
			content: "▹";
			padding-right: 5px;
			color: #e7db74;
			font-size: 1.2em;
		}	
	}
}

::selection {
	background: $selection-color; /* WebKit/Blink Browsers */
	color: $background;
}
::-moz-selection {
	background: $selection-color; /* Gecko Browsers */
	color: $background;
}